<template>
  <div class="home-container">
    <!-- 背景 -->
    <div class="bg">
      <div class="bg_image">临床常用药查询</div>
    </div>
    <!-- 查询 -->
    <div class="query">
      <van-field
        id="myInput"
        :center="true"
        v-model.trim="keyword"
        placeholder="请输入药品通用名"
        @keyup.enter.native="handleQuery"
      />
      <div class="query_btn" @click="handleQuery">查询</div>
    </div>
    <!-- 关注公众号 -->
    <Attention ref="attention" />
  </div>
</template>

<script>
import Attention from '@/components/Attention'
export default {
  data() {
    return {
      keyword: '',
    }
  },
  components: { Attention },
  methods: {
    handleQuery() {
      const token = localStorage.getItem('token')
      // if (!token) {
      //   return this.$refs.attention.attentionShow = true
      // }
      if (this.keyword === '') return this.Toast('请输入药品名称')
      this.$router.push({
        path: '/list',
        query: {
          keyword: this.keyword,
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.home-container {
  background-color: #f9faf9;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  .bg {
    width: 375px;
    height: 263px;
    background: #2364e8;
    border-radius: 0px 0px 14px 14px;
    .bg_image {
      height: 194px;
      text-align: center;
      line-height: 194px;
      color: #ffffff;
      font-size: 36px;
      background: url(../../assets/images/bg@3x.png) no-repeat;
      background-size: cover;
    }
  }
  .query {
    box-sizing: border-box;
    width: 333px;
    height: 186px;
    background: #ffffff;
    box-shadow: 0px 4px 8px 0px rgba(210, 210, 210, 0.5);
    border-radius: 11px;
    margin: -94px auto;
    padding: 32px 32px 0;
    .van-cell {
      background: #f1f2f5;
      border-radius: 8px;
      border: 1px solid #ffffff;
      &::before {
        content: "";
        width: 17px;
        height: 17px;
        background: url("../../assets/images/icon-search.png");
        background-size: 17px 17px;
        margin-right: 10px;
      }
    }
    .query_btn {
      width: 268px;
      height: 40px;
      line-height: 40px;
      margin-top: 32px;
      background: #2364e8;
      border-radius: 20px;
      text-align: center;
      color: #ffffff;
      font-size: 16px;
    }
  }
}
</style>