<template>
  <!-- 关注公众号 -->
  <div class="container">
    <van-popup round :closeable="true" v-model="attentionShow">
      <img
        style="width: 100%"
        src="../../assets/images/cmp_bg_public@3x.png"
        alt=""
      />
      <div class="content">
        <div class="hint">长按以下二维码，关注药先生公众号</div>
        <img
          class="ercode"
          src="../../assets/images/two-dimensional code@3x.png"
          alt=""
        />
        <div class="info">长按图片保存到手机</div>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  data() {
    return {
      attentionShow: false, // 关注公众号
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  /deep/ .van-popup--center.van-popup--round {
    width: 90%;
    height: 370px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    .hint {
      margin-top: 28px;
      color: #333;
      font-size: 16px;
    }
    .ercode {
      width: 119px;
      height: 119px;
      margin: 14px 0;
    }
    .info {
      font-size: 14px;
      color: #666;
    }
  }
}
</style>